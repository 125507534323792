import { createApp } from 'vue';
import { createPinia } from 'pinia';
import Vue3Toastify, { toast } from 'vue3-toastify';
import App from './InterfaceApp.vue';


if (typeof(interfaces) == 'object') {
    const pinia = createPinia();
    const app = createApp(App);
    const toastifyConfig = {
        limit: 3,
        autoClose: 5000,
        position: toast.POSITION.BOTTOM_RIGHT
    };
    
    app.use(pinia);
    app.use(Vue3Toastify, toastifyConfig);
    app.mount('#interfaces');
}    
