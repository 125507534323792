<template>
    <ModalLayout :store="store"></ModalLayout>
    <MenuCompare :store="store"></MenuCompare>
</template>
<script>
import useInterfaceStore from "@/stores/Interface";
import ModalLayout from "@/components/interfaces/ModalLayout.vue";
import MenuCompare from "@/components/interfaces/MenuCompare.vue";

export default {
    name: "InterfaceApp",
    components: {
        ModalLayout,
        MenuCompare
    },
    setup: () => ({ store: useInterfaceStore() }),
    mounted() {
        document.querySelectorAll('[data-action="open-modal"]').forEach(element => {
            element.addEventListener('click', () => { this.onAction(element )});
        });
    },
    methods: {
        onAction(element) {
            this.store.modal.setView(element.dataset.view, {
                id: element.dataset.id,
                name: element.dataset.name
            });
        }
    }
}
</script>